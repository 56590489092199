/**
 * 前端字典配置
 */
export const dictoryList = {
  // 紧急联系人关系
  urgentContactRelationArr: [
    { value: 0, label: '父母' },
    { value: 1, label: '兄弟姐妹' },
    { value: 2, label: '同事' },
    { value: 3, label: '同学' },
    { value: 4, label: '朋友' },
    { value: 5, label: '亲属' },
    { value: 6, label: '配偶' },
    { value: 7, label: '其他' }
  ],
  // 同住人关系
  occupantRelationList: [
    { value: 0, label: '配偶' },
    { value: 1, label: '子女' },
    { value: 2, label: '父母' },
    { value: 3, label: '亲属' },
    { value: 4, label: '合租人' },
    { value: 5, label: '其他' }
  ],
  // 性别
  sexArr: [
    { value: 0, label: '男' },
    { value: 1, label: '女' }
  ],
  // 职业
  jobArr: [
    { value: 0, label: '房产(房产/建筑)' },
    { value: 1, label: '金融(金融/银行/投资/保险)' },
    { value: 2, label: '医疗(医疗/护理/制药)' },
    { value: 3, label: '文化(文化/广告/传媒)' },
    { value: 4, label: '法律(律师/法务)' },
    { value: 5, label: '教育(教育/培训)' },
    { value: 6, label: '行政(公务员/行政/事业单位)' },
    { value: 7, label: '个体经营' },
    { value: 8, label: '贸易' },
    { value: 9, label: '物流' },
    { value: 10, label: '学生' },
    { value: 11, label: '其他' }
  ],
  // 账单类型
  billType: [
    { value: 0, label: '租约' },
    { value: 1, label: '押金' },
    { value: 2, label: '退款' },
    { value: 6, label: '退租' },
    { value: 3, label: ' 能耗' },
    { value: 5, label: '充值' },
    { value: 4, label: '其他费用' }
  ],
  // 抬头类型
  headingType: [
    { value: 1, label: '企业' },
    { value: 2, label: '个人' }
  ],
  // 发票类型
  invoiceType: [
    { value: 1, label: '电子普票' },
    { value: 2, label: '电子专票' },
    { value: 3, label: '纸质普票' },
    { value: 4, label: '纸质专票' }
  ],
  // 发票类型 电子
  invoiceTypeElectronic: [
    { value: 1, label: '电子普票' },
    { value: 2, label: '电子专票' }
  ],
  // 开票状态
  invoicingStatus: [
    { value: 1, label: '开票中' },
    { value: 2, label: '已开票' },
    { value: 3, label: '开票失败' },
    { value: 4, label: '冲红中' },
    { value: 5, label: '已冲红' },
    { value: 6, label: '冲红失败' }
  ],
  // 开票状态 (签约列表-详情-账单信息)
  invoicingStatusBill: [
    { value: -1, label: '不可开票' },
    { value: 0, label: '未开票' },
    { value: 1, label: '系统开票' },
    { value: 2, label: '人工开票' }
  ],
  // 是否开票
  invoicingWhether: [
    { label: '已开票', value: 3 },
    { label: '部分开票', value: 2 },
    { label: '未开票', value: 1 },
    { label: '不可开票', value: 0 }
  ],
  // 开票方式
  invoicingMethod: [
    { label: '系统开票', value: 1 },
    { label: '人工开票', value: 2 }
  ],
  // 是否征税
  Taxation: [
    { value: 1, label: '征税' },
    { value: 2, label: '免税' },
    { value: 3, label: '不征税' }
  ],
  // 发票 作废原因
  ReasonInvoiceCancellation: [
    { value: 0, label: '发票信息填写有误' },
    { value: 1, label: '发票账单选择有误' },
    { value: 2, label: '不需要该发票' }
  ],
  // 是否可开票
  optionsIsInvoice: [
    { value: 1, label: '可开票' },
    { value: 2, label: '不可开票' }
  ],
  // 房源类型
  propertyType: [
    { value: 0, label: '公寓租赁' },
    { value: 1, label: '写字楼租赁' }
  ],
  // 企业证件类型
  occupantEnterpriseCardType: [
    { value: 1, label: '统一社会信用代码' }
  ],
  // 证件类型
  iDType: [
    { value: 1, label: '身份证' },
    { value: 2, label: '护照' },
    { value: 3, label: '港澳通行证' },
    { value: 4, label: '台胞证' }
  ],
  // 红字确认单 状态
  confirmationStatus: [
    { value: 1, label: '申请中' },
    { value: 2, label: '待确认' },
    { value: 3, label: '作废' },
    { value: 4, label: '申请失败' },
    { value: 5, label: '已冲红' }
  ],
  // 红字发票
  creditNote: [
    { value: 0, label: '未开具' },
    { value: 1, label: '已开具' }
  ],
  // 红字发票 申请方身份
  applySource: [
    { value: 0, label: '销方' },
    { value: 1, label: '购方' }
  ],
  // 支付方式
  paymentMethod: [
    { value: 0, label: '微信' },
    { value: 1, label: '支付宝' },
    { value: 2, label: '现金' },
    { value: 3, label: '转账' }
  ],
  // 充值渠道
  rechargeChannel: [
    { value: -1, label: '初始化' },
    { value: 0, label: '微信' },
    { value: 1, label: '后台' }
  ],
  // 签约类型
  contractType: [
    { value: 0, label: '新签' },
    { value: 1, label: '续签' }
  ],
  // 设备类型
  equipmentType: [
    { value: 'ZHYQ-SB', label: '智能水表' },
    { value: 'ZHYQ-DB', label: '智能电表' }
  ],
  // 设备类型 单位
  equipmentTypeUnit: [
    { value: 'ZHYQ-SB', label: '吨' },
    { value: 'ZHYQ-DB', label: '度' }
  ],
  // 充值状态
  rechargeStatus: [
    { value: 0, label: '处理中' },
    { value: 1, label: '成功' },
    { value: 2, label: '失败' },
  ],
  // 资讯内容 状态
  informationContentStatus: [
    { value: 0, label: '未发布' },
    { value: 1, label: '已发布' },
    { value: 2, label: '已下架' }
  ],
  // 账单状态
  billingStatus: [
    { value: 0, label: '待收款' },
    { value: 1, label: '待付款' },
    { value: 2, label: '已结清' },
    { value: 3, label: '退租合并' },
    { value: 4, label: '手动作废' },
    // { value: 5, label: '退租失效' }
  ],
  // 账单状态（企业）
  billingStatus2: [
    { value: 1, label: '待收款' },
    { value: 0, label: '待付款' },
    { value: 2, label: '已结清' },
    { value: 3, label: '已作废' }
  ],
  // 场所变更类型
  locationChangeType: [
    { value: 1, label: '增加' },
    { value: 2, label: '缩减' },
    { value: 3, label: '调整' }
  ],
  // 合同状态
  contractStatusArr: [
    { value: 0, label: '即将搬入', count: 0, type: 'warning' },
    { value: 1, label: '租约中', count: 0, type: '' },
    { value: 2, label: '即将到期', count: 0, type: 'success' },
    { value: 3, label: '已到期', count: 0, type: 'danger' },
    { value: 6, label: '已续租', count: 0, type: 'info' },
    { value: 4, label: '已退租', count: 0, type: 'info' },
    { value: 5, label: '已作废', count: 0, type: 'info' }
  ],
  // 企业类型
  enterpriseType: [
    { label: '平台/机构', value: 2 },
    { label: '入孵企业', value: 1 },
    { label: '非入孵企业', value: 0 }
  ],
  // 资质类型
  qualificationType: [
    { label: '国家高新技术企业', value: 1 }, { label: '省级科技型中小企业', value: 2 }, { label: '民营科技企业', value: 3 },
    { label: '瞪羚企业', value: 4 }, { label: '省级专精特新中小企业', value: 5 }, { label: '企业技术中心', value: 6 },
    { label: '省级科技小巨人企业', value: 7 }, { label: '工程技术研究中心', value: 8 }, { label: '雏鹰企业', value: 9 },
    { label: '众创空间', value: 10 }, { label: '专精特新小巨人企业', value: 11 }, { label: '省级创新型中小企业', value: 12 },
    { label: '科技企业孵化器', value: 13 }, { label: '技术创新示范企业', value: 14 }, { label: '隐形冠军企业', value: 15 },
    { label: '技术先进型服务企业', value: 16 }, { label: '独角兽企业', value: 17 }, { label: '规上企业', value: 18 },
    { label: '省级产业领军团队', value: 19 }
  ],
  // 知识产权类型
  intellectualPropertyType: [
    { label: '发明专利', value: 1 }, { label: '实用新型专利', value: 2 }, { label: '外观专利', value: 3 },
    { label: '集成点图布图设计', value: 4 }, { label: '商标信息', value: 5 }, { label: '软件著作权', value: 6 },
    { label: '其他', value: 7 }
  ],
  // 产权状态
  propertyStatus: [
    { label: '申请中', value: 1 },
    { label: '已获得', value: 2 }
  ],
  // 企业状态
  enterpriseStatus: [
    { label: '在园', value: 1 },
    { label: '正常毕业', value: 2 },
    { label: '提前毕业', value: 3 },
    { label: '正常退出', value: 4 },
    { label: '提前退出', value: 5 },
    { label: '强制退出', value: 6 },
    { label: '无', value: 7 }
  ],
  // 根据key信息
  getDictionaryByKey(key, arrName) {
    if (!key && key !== 0) return {}
    if (!Array.isArray(dictoryList[arrName])) {
      console.log('未找到 ' + arrName + ' ###')
      return {}
    }
    const result = dictoryList[arrName].find((item) => {
      return item.value.toString() === key.toString()
    })
    return result || {}
  }
}
